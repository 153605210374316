<template>
  <div>
    <h2>利用規約</h2>
    <p>
      本利用規約は、本Webサイト(以下、本サイト)が提供するサービス「Swiss Draw
      Manager」（以下、本サービス）における利用条件を定めるものです。
      本サービスの利用者（以下、ユーザー）は、本サービスの利用を以て利用規約に同意するものといたします。
    </p>
    <p>
      本サービスは、ユーザーが送信した情報に基づき、スイス式トーナメント大会における戦績管理、マッチング作成などの機能により大会の進行を補助し、また大会に紐づく情報を本サービスが生成したURLを通じて他のユーザーに共有する機能を有するものとします。
    </p>
    <p>
      本サイトは、ユーザーが本サービスに送信した情報を、本サービスの提供のために利用できるものとし、かつその情報を無期限に保持できるものとします。
    </p>
    <p>
      本サイトは、ユーザーが送信した情報を事前の告知なく改変または削除できるものとします。
    </p>
    <p>
      本サイトは、本サービスの内容を事前の告知なく自由に変更、廃止できるものとします。
    </p>
    <p>
      本サイトは、ユーザーが本サービスを利用することによって被ったいかなる損害について、一切の責任を負いません。
    </p>
    <p>
      ユーザーは、本サービスの利用にあたり、下記のいずれかに該当又はいずれかに類する行為をしてはなりません。
    </p>
    <ul>
      <li>
        本サイト、本サービスの他のユーザー、その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
      </li>
      <li>犯罪行為に関連する行為又は法律・条例・公序良俗に反する行為</li>
      <li>猥褻な情報又は青少年に有害な情報を送信する行為</li>
      <li>本サービスの提供に支障をきたす又は支障をきたすおそれがある行為</li>
      <li>本サイトへの不正アクセス行為や過度な連続アクセス行為</li>
      <li>その他、本サイトが不適切と判断する行為</li>
    </ul>
    <p>
      本サイトは、ユーザーによる行為が前項各号のいずれかに該当又は該当するおそれがあると判断した場合、当ユーザーへの事前の通知なく以下の措置を講ずることができるものとします。
    </p>
    <ul>
      <li>本サイト上又はその他適切なWebサイト上での違反内容の公表</li>
      <li>違反行為の対象となる内容の改変または削除</li>
      <li>本サービスの利用停止</li>
      <li>本サイトへのアクセス制限</li>
      <li>その他、本サイトが必要と判断する処分</li>
      <li>
        前各号の措置を行った事実の、本サイト上又はその他適切なWebサイト上での公表
      </li>
    </ul>
    <p>本サイトは、本規約を事前の告知なく変更、廃止できるものとします。</p>
  </div>
</template>

<script>
export default {
  name: "Tos",
};
</script>

<style>
</style>