<template>
  <div class="home container my-3">
    <div class="about">
      <h3>{{ $t('label.about') }}</h3>
      <i18n path="message.about" tag="p">
        <a :href="$t('url.swissDraw')">{{ $t('message.swissDraw') }}</a>
      </i18n>
    </div>

    <div class="mb-3">
      <h3>{{ $t('label.tos') }}</h3>
      <p>
        <a href="" data-toggle="modal" data-target="#tos">{{ $t('message.tos') }}</a>
      </p>
    </div>

    <div>
      <h3>{{ $t('label.forOrganizer') }}</h3>
      <p>
        {{ $t('message.forOrganizer') }}
      </p>
      <div class="mb-3">
        <button class="btn btn-primary" @click="newGame" :disabled="isGenerating">
          <span class="spinner-border spinner-border-sm" v-show="isGenerating"></span>
          {{ $t('label.newEvent') }}
        </button>
      </div>
      
      <h3>{{ $t('label.forPlayer') }}</h3>
      <p>
        {{ $t('message.forPlayer') }}
      </p>
    </div>

    <div class="mb-3">
      <h3>{{$t('label.createdEvents')}}</h3>
      <div>
        <div id="backup-list" class="list-group">
          <template v-if="backups.length">
            <router-link
              class="list-group-item list-group-item-action"
              v-for="b in backups"
              :key="b.id"
              :to="`/${lang}/man/${b.id}/`">
              {{ $t('label.created') }}: {{ b.created | timestamp }}
            </router-link>
          </template>
          <div v-else
            class="alert alert-secondary my-2">
            No events
          </div>
        </div>
      </div>
    </div>
    
    <p>
      <span>
        <a href="https://bitbucket.org/shama265/swiss-draw-manager/" target="_blank">{{ $t('message.sourcePublish') }}</a>
      </span>
      <i18n path="message.issueAnnounce" tag="span">
        <a href="https://bitbucket.org/shama265/swiss-draw-manager/issues" target="_blank">{{ $t('message.issueTracker') }}</a>
      </i18n>
    </p>

    <p>
      <a href="https://twitter.com/rzna_lash" target="_blank">Twitter: @rzna_lash</a>
    </p>

    <p class="copylight">
      <small>&copy; 2020-2022 by RZNA</small>
    </p>
    
    <div class="modal fade" id="tos">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <tos />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import db from '@/persistent/dexie'
import Tos from './Tos.vue'

export default {
  components: { Tos },
  name: "Home",
  data() {
    return {
      isGenerating: false,
    }
  },
  created() {
    this.$store.dispatch('initBackups')
  },
  computed: {
    backups() {
      return this.$store.state.backups
    },
    lang() {
      return this.$route.params.lang
    },
  },
  methods: {
    newGame() {
      this.isGenerating = true
      axios.get(process.env.VUE_APP_BACKEND_HOST + '/api/gen')
      .then(resp => {
        console.log(resp.data)
        return {
          id: resp.data.access,
          phrase: resp.data.phrase,
          created: new Date()
        }
      })
      .then(async (data) => {
        await db.eventHistory.put(data)
        this.$router.push(`/${this.lang}/man/${data.id}/`)
      })
      .finally(() => {
        this.isGenerating = false
      })
    },
  },
  filters: {
    timestamp(value) {
      var date = new Date(value)
      return date.toLocaleString()
    }
  },
}
</script>
<style scoped>
.summary .col-key {
  width: 8rem;
}
</style>
<i18n>
{
  "en": {
    "label": {
      "summary": "Summary",
      "status": "Status",
      "players": "Players",
      "about": "About",
      "usage": "Usage",
      "created": "Created",
      "createdEvents": "Created Events (Last 10 items)",
      "newEvent": "New Event",
      "forOrganizer": "For organizers",
      "forPlayer": "For players",
      "tos": "Term of Service"
    },
    "message": {
      "checkin": "Checking in",
      "roundRunning": "Round {count} is running",
      "roundFinished": "Round {count} is finished",
      "about": "The supporting tool for running {0}",
      "swissDraw": "Swiss-system tournament",
      "usage": {
        "rule": "@:label.rule - decide tournament rules",
        "checkin": "@:label.checkin - register players",
        "table": "@:label.table - record each result of plays",
        "leaderboard": "@:label.leaderboard - check player places"
      },
      "sourcePublish": "The source code is available on Bitbucket.",
      "issueAnnounce": " If some problems with this tool, please let me know via {0}.",
      "issueTracker": "issue tracker on Bitbucket",
      "forOrganizer": "Create a new event page by clicking a button below. Otherwise, choose an event from created event list.",
      "forPlayer": "Access from a link or QR Code shared by an event organizer.",
      "tos": "Click here to read (Japanese)"
    },
    "url": {
      "swissDraw": "https://en.wikipedia.org/wiki/Swiss-system_tournament"
    }
  },
  "ja": {
    "label": {
      "summary": "概要",
      "status": "状態",
      "players": "選手",
      "about": "このツールについて",
      "usage": "使い方",
      "created": "作成日時",
      "createdEvents": "作成した大会 (最近の10件)",
      "newEvent": "大会を作成",
      "forOrganizer": "大会主催者の方へ",
      "forPlayer": "大会参加者の方へ",
      "tos": "利用規約"
    },
    "message": {
      "checkin": "チェックイン中",
      "roundRunning": "{count}回戦進行中",
      "roundFinished": "{count}回戦終了",
      "about": "{0}の運営を支援するツールです。",
      "swissDraw": "スイスドロー大会",
      "usage": {
        "rule": "@:label.rule - 大会のルールを定めます。",
        "checkin": "@:label.checkin - 選手を登録します。",
        "table": "@:label.table - 各試合の結果を記録します。",
        "leaderboard": "@:label.leaderboard - 順位を確認します。"
      },
      "sourcePublish": "本ツールのソースコードはBitbucket上にて公開しています。",
      "issueAnnounce": "お気づきの点がありましたら、{0}よりお気軽にお知らせください。",
      "issueTracker": "課題トラッカー",
      "forOrganizer": "下記のボタンから新しい大会用ページを作成してください。または、作成した大会一覧から既存の大会を選択してください。",
      "forPlayer": "大会主催者から共有されたリンクまたはQRコードからアクセスしてください。",
      "tos": "こちらからお読みください。"
    },
    "url": {
      "swissDraw": "https://ja.wikipedia.org/wiki/スイス式トーナメント"
    }
  }
}
</i18n>
